import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import "./App.css";
import { shuffleArray } from "./utils";

function App() {
  const [listOfNames, setListOfNames] = useState<string[]>([]);
  const [currentInput, setCurrentInput] = useState("");
  const [listOfGivers, setListOfGivers] = useState<string[]>([]);
  const [combinedList, setCombinedList] = useState([
    { giver: "", receiver: "" },
  ]);
  const [listOfReceivers, setListOfReceivers] = useState<string[]>([]);
  const [listComplete, setListComplete] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    function doArraysEqual() {
      for (let index = 0; index < listOfGivers.length; index++) {
        if (listOfGivers[index] === listOfReceivers[index]) {
          return true;
        }
      }
      return false;
    }
    if (calculating) {
      if (listOfGivers.length !== 1 && doArraysEqual()) {
        setListOfReceivers(shuffleArray(Array.from(listOfNames)));
      } else {
        const mixed = listOfGivers.map((giver, index) => {
          return { giver: giver, receiver: listOfReceivers[index] };
        });
        setCombinedList(mixed);
        setListComplete(true);
        setCalculating(false);
      }
    }
  }, [listOfReceivers, calculating, listOfNames, listOfGivers]);

  function handleKeyDown({ key }: { key: string }) {
    if (key === "Enter") {
      setListOfNames([...listOfNames, currentInput]);
      setCurrentInput("");
    }
  }

  function checkIfDuplicateExistsInListOfNames() {
    return new Set(listOfNames).size !== listOfNames.length;
  }

  function createList() {
    if (checkIfDuplicateExistsInListOfNames()) {
      handleDialogClickOpen();
    } else {
      setListOfGivers(shuffleArray(Array.from(listOfNames)));
      setListOfReceivers(shuffleArray(Array.from(listOfNames)));

      setCalculating(true);
    }
  }

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function deleteName(removeIndex: number) {
    setListOfNames(listOfNames.filter((item, index) => index !== removeIndex));
  }

  return (
    <Box
      className="App"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      display="flex"
    >
      <Box display={"none"}>
        The Simplest Way to Organize Your Gift Exchange. Our name drawing tool
        takes the hassle out of organizing your gift exchange.
      </Box>
      <Container maxWidth="md">
        <Typography variant="body1" gutterBottom>
          Always free to use. We don't collect any data, or serve any ads. Type
          names below, copy your results, or take a picture to share with
          friends and family. Enjoy!
        </Typography>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap", mt: 2 }}>
          {listOfNames.map((person: string, index: number) => {
            return (
              <Box key={index}>
                <Chip
                  key={index}
                  label={person}
                  variant="outlined"
                  onDelete={() => deleteName(index)}
                  sx={{ mb: "5px" }}
                />
              </Box>
            );
          })}
        </Stack>
        <TextField
          sx={{ marginTop: "20px" }}
          label="Type names here and press return"
          variant="outlined"
          focused
          autoFocus
          fullWidth
          ref={null}
          type="text"
          value={currentInput}
          onKeyDown={handleKeyDown}
          onChange={(event) => setCurrentInput(event.target.value)}
        />
        {!calculating && (
          <Button
            variant="contained"
            onClick={createList}
            sx={{ margin: "20px" }}
          >
            Create Shuffled List
          </Button>
        )}
        {calculating && <CircularProgress />}
        {listComplete && (
          <Box>
            <TableContainer component={Paper} sx={{ mb: 5 }}>
              <Table id="results-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Giver</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Receiver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {combinedList.map((list) => (
                    <TableRow key={list.giver}>
                      <TableCell>{list.giver}</TableCell>
                      <TableCell>{list.receiver}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{"Duplicate Names?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              It looks like there are duplicate names. Try adding last names to
              make them unique.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default App;
